import React from 'react';
import { Routes, Route } from "react-router-dom";
import NotFound from './errors/NotFound'
import Login from './Login';
import Logout from './Logout';
import Home from './Home';
import Clienti from './Clienti';
import ClientiDettaglio from './ClientiDettaglio';
import Contratti from './Contratti';
import ContrattiDettaglio from './ContrattiDettaglio';
import Listino from './Listino';
import ListinoDettaglio from './ListinoDettaglio';
import Servizi from './Servizi';
import ServiziDettaglio from './ServiziDettaglio';
import Scadenze from './Scadenze';

import FattureInCloud from './sync/FattureInCloud';
import ArubaBusiness from './sync/ArubaBusiness';
import PianetaFibra from './sync/PianetaFibra';

export default function MyRoutes({setTitle, setContextCommands}) {
  const defaultProps = {setTitle, setContextCommands};
  return (
    <Routes>
      <Route path="/" element={ <Home {...defaultProps} /> } />
      <Route path="/login" element={ <Login {...defaultProps} /> } />
      <Route path="/clienti" element={ <Clienti {...defaultProps} /> } />
      <Route path="/clienti/:id" element={ <ClientiDettaglio {...defaultProps} /> } />
      <Route path="/contratti" element={ <Contratti {...defaultProps} /> } />
      <Route path="/contratti/:id" element={ <ContrattiDettaglio {...defaultProps} /> } />
      <Route path="/listino" element={ <Listino {...defaultProps} /> } />
      <Route path="/listino/:id" element={ <ListinoDettaglio {...defaultProps} /> } />
      <Route path="/servizi" element={ <Servizi {...defaultProps} /> } />
      <Route path="/servizi/:id" element={ <ServiziDettaglio {...defaultProps} /> } />
      <Route path="/scadenze" element={ <Scadenze {...defaultProps} /> } />

      <Route path="/sync/fattureincloud" element={ <FattureInCloud {...defaultProps} /> } />
      <Route path="/sync/arubabusiness" element={ <ArubaBusiness {...defaultProps} /> } />
      <Route path="/sync/pianetafibra" element={ <PianetaFibra {...defaultProps} /> } />
      <Route path="/logout" element={ <Logout {...defaultProps} /> } />  
      <Route path="*" element={ <NotFound {...defaultProps} /> } />
    </Routes>
  )
}
