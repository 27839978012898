import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function Listino({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		{ field: 'id', headerName: 'ID', width: 100, type:'number' },
		{ field: 'code', headerName: 'Codice', width: 220 },
		{ field: 'name', headerName: 'Titolo prodotto', flex:true},
		{ field: 'category', headerName: 'Categoria', width: 160 },
		{ field: 'net_price', headerName: 'Prezzo netto', width: 120, type:'number', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: 'gross_price', headerName: 'Prezzo lordo', width: 120, type:'number', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
	]

	const caricaRows = () => {
		api.get("/prodotti/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Listino');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/prodotti/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/listino/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			/*<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/clienti/new") }>Aggiungi</ContextCommand>,*/
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/listino/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti i prodotti/servizi che sono presenti su Fatture in Cloud.
			</p>
			<DataGrid
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
					paginationModel: {
						pageSize: 15,
					},
					},
				}}
				slots={{
					toolbar: GridToolbar,
				}}
				pageSizeOptions={[15]}
				checkboxSelection
				onRowSelectionModelChange={handleSelectionChange}
				disableRowSelectionOnClick
				onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
