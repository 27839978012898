import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const FormSelect=({name,size,defaultValue="",label,rules,xs=12,md=6,control,options,disabled,valueField='value',labelField='label',multiple,onChange})=>(
  <Grid item xs={xs} md={md} style={{marginBottom:5}}>
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => 
        <FormControl size={size} fullWidth error={!!fieldState.error} disabled={disabled} >
          <InputLabel>{label}</InputLabel>
          <Select onChange={onChange} label={label} multiple={multiple} fullWidth {...field} >
            {options.map(o=>
              <MenuItem value={o[valueField]} key={o[valueField]}>{o[labelField]}</MenuItem>
            )}
          </Select>
        </FormControl>
      }
    />
  </Grid>)

  export default FormSelect;