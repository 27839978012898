import React, {useEffect} from 'react';

export default function NotFound(props) {
  useEffect(()=>props.setTitle("Pagina non trovata"),[props])
  return (
    <div>
      <h1>Pagina non trovata</h1>
      <p>L'indirizzo digitato non è valido.</p>
		</div>
    );

}
