import React, {useState,useRef,useEffect} from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import DrawerMenuItem from './DrawerMenuItem';
import Typography from '@mui/material/Typography';
import Icon from '../Icon';
import Logo from '../../img/logo.png';

export default function DrawerContent(props) {
  const scrollbarWidth=17;
  const [contentWidth,setContentWidth]=useState(0);
  const [containerWidth,setContainerWidth]=useState(0);
  const container=useRef(null);

  useEffect(()=>{
    setContainerWidth(container.current.clientWidth);
  }, [container]);

  useEffect(()=>{
    setContentWidth(containerWidth-scrollbarWidth);
  }, [scrollbarWidth, containerWidth]);


  return (
    <div ref={container}>
      <img src={Logo} alt="Lanfranco Stefano" style={{width: contentWidth/1.3, padding:15, marginLeft:contentWidth/6}} />
      <Divider />
      <List style={{width: contentWidth}}>
        {props.menu.map((m,i) => (
          <DrawerMenuItem {...m} key={i} />
        ))}
      </List>
      <Divider />
      <center style={{marginTop:20}}>
        <Typography variant="body2">
          Versione: <Icon icon="update" /> {process.env.REACT_APP_VERSION}<br />
          <Icon icon="account" /> { localStorage.getItem("username") }
        </Typography>
      </center>
    </div>
  );
}
