import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from './lib/api';
import { setCookie, deleteCookie } from './lib/cookie';
import Logo from './img/logo.png';

const theme = createTheme();

export default function Login() {
  const [ failed, setFailed ] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = btoa(data.get('username') + ":" + data.get('password'));
    localStorage.setItem('username', data.get('username'));
    setCookie("auth", auth);
    api.get("/auth").then(
      r=>{
        window.location.reload();
      },
      err=>{
        deleteCookie("auth");
        setFailed(true);
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          <img src={Logo} style={{width:'100%'}} alt="Lanfranco Stefano"/>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {failed && <center><Typography style={{color:'red',marginTop:20}}>Username o password errati</Typography></center>}
            <TextField margin="normal" required fullWidth name="username" label="Username" autoComplete="username" autoFocus />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" autoComplete="current-password" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Accedi
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
