import React from 'react';

export default function Icon(props) {
  const size = props.size || "1em"
  var style={
    fontSize: size,
    ...props.style,
  }
  if (props.color) style["color"]=props.color;
  return (<span className={"mdi mdi-" + props.icon} onClick={props.onClick} style={style}></span>)
}
