import React, { useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import useNotification from './../../components/useNotification';
// import api from '../../lib/api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export default function PianetaFibra({ setTitle, setContextCommands }) {
    const navigate = useNavigate();
    const { pushNotification } = useNotification();

    useEffect(() => {
        setTitle('SYNC Pianeta Fibra');
        const contextCommands = [];
        setContextCommands(contextCommands);
    }, [setTitle, setContextCommands]);

    const handlePianetaFibra = useCallback(() => {
        pushNotification("Funzionalità NON attiva.", "error");
        navigate("/sync/pianetafibra");
        return true;
    }, [pushNotification, navigate]);


    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <p>
                Questa pagina, serve a sincronizzare i dati presenti su Pianeta Fibra.
            </p>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained"
                        sx={{ width: '80%' }}
                        onClick={handlePianetaFibra}
                    >
                        SYNC di tutte le connessioni
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
