import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormTitle from '../components/FormTitle';
import FormTextField from '../components/FormTextField';
import FormSelect from '../components/FormSelect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function ContrattiDettaglio({setTitle, setContextCommands}) {
    const { id } = useParams();
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const { pushNotification } = useNotification();
    const { control, handleSubmit, setValue, reset } = useForm({mode: 'all'});
    const navigate = useNavigate();
    const [rowsServizi, setRowsServizi] = useState([]);
    const [clientiFattureInCloud, setClientiFattureInCloud] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [serviziDisponibili, setServiziDisponibili] = useState([]);

    const columnsServizi = [
        { field: 'servizio', headerName: 'ID', width: 80, type: 'number' },
        { field: 'article', headerName: 'Articolo', width: 300, type: 'text', flex: true },
        { field: 'domain', headerName: 'Dominio', width: 300, type: 'text' },
        { field: 'expirationDate', headerName: 'Scadenza', width: 140, valueGetter: (params) => DateTime.fromSQL(params.row.expirationDate).toFormat('dd/MM/yyyy') },
        { field: 'quantity', headerName: 'Quantità', width: 80, type: 'number' },
        { field: 'state', headerName: 'Stato', width: 150, valueGetter: (params) => {
            switch (params.row.state) {
                case 'Active':
                    return 'Attivo';
                case 'Canceled':
                    return 'Cancellato';
                case 'ToCancel':
                    return 'In cancellazione';
                case 'Rejected':
                    return 'Respinto';
                default:
                    return params.row.state;
            }
        }}
    ];

    const handleAddRow = () => {
        setDialogOpen(true);
        // Carica l'elenco dei servizi disponibili
        api.get('/servizi/elenco').then(data => {
            setServiziDisponibili(data);
        }, error => {
            console.log("Impossibile caricare i dati dei servizi disponibili", "error");
        });
    };

    const handleSelectService = (service) => {
        const riga = {contratto:id , servizio:service.id };
        setRowsServizi(prevRows => [...prevRows, riga]);
        const json = nullZLSTabella(riga);
        json._action = "n";
        console.log(json);

        api.post("/contratti/servizi", json).then(r => {
            pushNotification("Servizio aggiunto", "success");
            setDialogOpen(false);
            caricaContratto();
        }, error => {
            pushNotification("Errore nel salvataggio del servizio", "error");
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const caricaContratto = () => {
        if (id !== 'new') {
            api.get('/contratti/' + id).then(data => {
                const contratto = data[0];
                contratto._action = "e";
                api.get('/contratti/' + id + '/servizi').then(data => {
                    setRowsServizi(data);
                }, error => {
                    console.log("Impossibile caricare i dati", "error");
                });
                reset(contratto);
            }, error => {
                console.log("Impossibile caricare i dati", "error");
            });
        }
        api.get("/clienti/elenco?sort=name").then(data => {
            setClientiFattureInCloud(data);
        }, error => {
            console.log("Impossibile caricare i dati", "error");
        });
    };

    useEffect(() => {
        setLoaderOpen(true);
        setTitle('Dettaglio del contratto');
        const contextCommands = [];
        setContextCommands(contextCommands);
        caricaContratto();
    }, [setTitle, setContextCommands]);

    useEffect(() => {
        if (id === 'new') {
            setValue('_action', 'n');
            setLoaderOpen(false);
        } else {
            api.get(`/contratti/${id}`).then(data => {
                if (data.length !== 1) {
                    alert('Errore nel caricamento dei dati');
                } else {
                    const cliente = data[0];
                    cliente._action = "e";
                }
                setLoaderOpen(false);
            });
        }
    }, [id, setValue]);

    const salva = useCallback(data => {
        if (data._action === "e") {
            delete data.name;
        }
        var array = [];
        array.push(data);
        const json = nullZLSTabella(array);
        api.post("/contratti", json).then(r => {
            pushNotification("Dati salvati", "success");
            // navigate("/contratti/" + r);
            navigate("/contratti/");
        }, error => {
            pushNotification("Errore nel salvataggio del contratto", "error");
        });
    }, [pushNotification, navigate]);

    useEffect(() => {
        setContextCommands([
            <ContextCommand key="1" icon="backspace" onClick={() => navigate("/contratti")}>Indietro</ContextCommand>,
            <ContextCommand key="2" icon="floppy" onClick={handleSubmit(data => salva(data))}>Salva</ContextCommand>
        ]);
    }, [setContextCommands, handleSubmit, navigate, salva]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Loader open={isLoaderOpen} />
            <Grid container spacing={2}>
                <FormTextField xs={12} md={6} size="small" control={control} name="id" label="ID n°" type="text" disabled />
                {id !== "new" ? (
                    <FormTextField xs={12} md={6} size="small" control={control} name="name" label="Cliente" type="text" disabled />
                ) : (
                    <FormSelect xs={12} md={6} control={control} name='cliente' label="Cliente" options={clientiFattureInCloud} valueField='id' labelField='name' />
                )}
                <FormTextField xs={12} md={12} size="small" placeholder="Descrizione" control={control} name="descrizione" label="Descrizione" type="text" />
                <FormTextField xs={6} md={6} size="small" control={control} name="data_inizio" label="Contratto iniziato il" type="date" required/>
                <FormTextField xs={6} md={6} size="small" control={control} name="data_fine" label="terminato il" type="date" />
            </Grid>
            <br /><br />
            <FormTitle variant="h6">Servizi di Aruba Business nel contratto</FormTitle>
            <Button variant="contained" color="info" sx={{ width: '100%' }} onClick={handleAddRow}>
                AGGIUNGI UN SERVIZIO AL CONTRATTO
            </Button>
            <br /><br />
            <DataGrid
                rows={rowsServizi}
                columns={columnsServizi}
                slots={{
                    toolbar: GridToolbar,
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                getRowId={r => r.servizio}
                pageSizeOptions={[10]}
            />
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <DialogTitle>Seleziona un Servizio</DialogTitle>
                <DialogContent>
                    <DataGrid
                        rows={serviziDisponibili}
                        columns={[
                            { field: 'id', headerName: 'ID', width: 80 },
							{ field: 'article', headerName: 'Descrizione', width: 300 },
                            { field: 'domain', headerName: 'Dominio', width: 200 },
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                        }}
                        pageSizeOptions={[25]}
                        onRowClick={(params) => handleSelectService(params.row)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Chiudi</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}